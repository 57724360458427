@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl;
    font-weight: 600;
  }
  h2 {
    @apply text-2xl;
    font-weight: 600;
  }
  h3 {
    @apply text-2xl;
    font-weight: 600;
  }
  h4 {
    @apply text-xl;
    font-weight: 600;
  }
  h5 {
    @apply text-lg;
    font-weight: 600;
  }

  body {
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }

  *,
  ::before,
  ::after {
    @apply dark:border-gray-600;
  }
}

ul {
  list-style: inherit;
  margin-left: 20px;
}

.react-loading-skeleton {
  --base-color: #eee;
  --highlight-color: #fff;
}

.dark .react-loading-skeleton {
  --base-color: #18181b;
  --highlight-color: #333;
}

/*
  Remove the border when you click on a slide.
  Currently, it is not possible to control from the props in the <Slide /> component.
*/
.carousel__slide-focus-ring {
  outline: none;
}
